import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'

import { Page, View, Text, WhiteGreyLayout, SEO, Button, Input } from 'lib'
import theme from '_appSetup/Theme'
import firebase from 'gatsby-plugin-firebase'

import { useUserData, formatAPY } from '../dashboard/_utils'
import { ChangePasswordDialog, TwoFASetupDialog, TwoFADialog } from './_dialogs'
import { fetchUserData } from 'actions/User'
import { Dialog } from '@capacitor/dialog'
import { NativeBiometric } from 'capacitor-native-biometric'
import { biometricCheck } from './_biometrics'

import '_appSetup/Global.css'

const ProfileDetails = (props) => {
  const styles = useStyles(props)

  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false)
  const [twoFASetupDialogOpen, setTwoFASetupDialogOpen] = useState(false)
  const [resetDialogOpen, setResetDialogOpen] = useState(false)
  const [nativeBiometricAvailable, setNativeBiometricAvailable] = useState(false)
  const [biometricLoading, setBiometricLoading] = useState(false)

  useEffect(() => {
    NativeBiometric.isAvailable().then(result => {
      if (result.isAvailable) setNativeBiometricAvailable(true)
    })
  }, [])

  const reset2FA = async () => {
    const set2FASecretFunc = firebase.functions().httpsCallable('set2FASecret')
    await set2FASecretFunc({ secret: undefined })
    await fetchUserData({})
    setResetDialogOpen(false)
  }

  const setBiometricEnabled = async (on) => {
    setBiometricLoading(true)
    if (on) {
      biometricCheck({
        successCallback: async () => {
          const serverFunc = firebase.functions().httpsCallable('setBiometricEnabled')
          await serverFunc({ enabled: true })
          await fetchUserData({})
          setBiometricLoading(false)
        },
        failureCallback: (error) => {
          Dialog.alert({
            title: 'Error',
            message: 'Biometric verification failed.',
          })
          setBiometricLoading(false)
        },
      })
    } else {
      const serverFunc = firebase.functions().httpsCallable('setBiometricEnabled')
      await serverFunc({ enabled: null })
      await fetchUserData({})

      setBiometricLoading(false)
    }
  }

  const { UserData } = useUserData()
  const isBusiness = UserData?.accountType == 'BUSINESS'

  const headerText = isBusiness ? 'Business account details' : 'Account details'
  const name = UserData.businessName || UserData.fullName || `${UserData.firstName || ''} ${UserData.lastName || ''}`

  return (
    <>
      <Text variant='h5' style={styles.header} text={headerText}/>

      <Text gutterBottom text={name}/>
      <Text gutterBottom>{UserData.email}</Text>

      <Button onClick={() => setChangePasswordDialogOpen(true)} color='primary' variant='outlined' style={styles.button}>
        Change your password
      </Button>

      <View style={styles.separator}/>
      <Text variant='h5' style={styles.header} gutterBottom>Security</Text>

      {UserData['2FASecret'] ? (
        <>
          <Text gutterBottom>Two factor authentication is enabled!</Text>
          <Button onClick={() => setResetDialogOpen(true)} color='primary' variant='outlined' style={styles.button}>
            Remove two factor authentication
          </Button>
        </>
      ) : (
        <>
          <Text gutterBottom>Two factor authentication is currently disabled. By enabling this security feature, you protect your account by requiring more than just your password to perform actions like logging in and withdrawing funds.</Text>
          <Button onClick={() => setTwoFASetupDialogOpen(true)} color='primary' variant='contained' style={styles.button}>
            Set up 2FA (two factor authentication)
          </Button>
        </>
      ) }

      <View style={styles.spacer}/>

      {nativeBiometricAvailable ? UserData.BiometricEnabled ? (
        <>
          <Text gutterBottom>Mobile biometric authentication is enabled!</Text>
          <Button onClick={() => setBiometricEnabled(false)} color='primary' variant='outlined' style={styles.button} loading={biometricLoading}>
            Turn biometric authentication off
          </Button>
        </>
      ) : (
        <>
          <Text gutterBottom>Mobile biometric authentication is off. Enable it to protect your account by requiring a fingerprint or face scan whenever you open the app.</Text>
          <Button onClick={() => setBiometricEnabled(true)} color='primary' variant='contained' style={styles.button} loading={biometricLoading}>
            Turn biometric authentication on
          </Button>
        </>
      ) : null}

      <ChangePasswordDialog
        open={changePasswordDialogOpen}
        closeDialog={() => setChangePasswordDialogOpen(false)}
      />

      <TwoFASetupDialog
        open={twoFASetupDialogOpen}
        closeDialog={() => setTwoFASetupDialogOpen(false)}
      />

      <TwoFADialog
        open={resetDialogOpen}
        closeDialog={() => setResetDialogOpen(false)}
        onConfirm={reset2FA}
      />

    </>
  )
}

const ProfilePage = (props) => {
  const styles = useStyles(props)

  const LeftComponent = () => {
    return (
      <View style={styles.leftWrapper}>

        <View style={styles.depositInfoWrapper}>
          <ProfileDetails/>
        </View>
      </View>
    )
  }

  return (
    <Page>
      <SEO title='Account settings'/>
      <WhiteGreyLayout
        leftComponent={LeftComponent}
        rightComponent={() => null}
      />
    </Page>
  )
}


const useStyles = makeStyles({
  leftWrapper: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  depositInfoWrapper: {
    paddingBottom: theme.spacing(4),
    alignSelf: 'stretch',
    wordBreak: 'break-word',
  },
  head: {
    marginBottom: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  textHeader: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  button: {
    marginLeft: 0,
  },
  separator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0.5),
    borderTop: '1px solid #00000016',
    paddingTop: theme.spacing(4),
  },
  spacer: {
    height: theme.spacing(2),
  },
})

export default ProfilePage
